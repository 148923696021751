import React from "react";

const Service = () => {
  
  return (
    <>
    Aman
    </>
  );
};
export default React.memo(Service);
